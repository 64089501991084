.info-content {
	position: absolute;
	top: 70px;
	right: 70px;
	bottom: 70px;
	max-width: calc(100% - 140px);
	padding: 30px;
	background-color: #fff;
	border-radius: 20px;

}
.info-cnt-close {
	position: absolute;
	top: 0;
	right: -55px;
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
	cursor: pointer;
}
