.cmp-group.border {
	border: 2px solid #f3f3f3;
	border-radius: 15px;
	padding: 10px;
	margin: 20px 0;
	position: relative;
}

.cmp-group .title {
	padding: 0 17px;
}

.cmp-group.border .title {
	position: absolute;
	background-color: #fff;
	top: -15px;
	left: 10px;
	padding: 0 5px;
}
