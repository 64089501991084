.logo-l {
	display: block;
	width: 155px;
	min-width: 155px;
	height: 36px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	cursor: pointer;
	margin: 0 10px;
}
