.signup-box {
	position: relative;
	width: 100vw;
	height: 100vh;
	background-size: cover
}
.signup-box .phone {
	margin-top: 20px;
}
.signup-box .shadow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	opacity: 0.5;
}
.signup-box .container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	input {
		font-size: 16px;
	}
	.signup-box {
		all: unset;
	}
}