.login-box .info {
	position: relative;
	background-color: #F3F3F3;
	border-radius: 0 0 20px 20px;
}
.login-box .info .ok {
	position: absolute;
	top: -12px;
	left: 50%;
	margin-left: -12px;
	width: 24px;
	height: 24px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 15px;
	background-color: #EE5130;
	border-radius: 50%;
}
.login-box .info ul {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	list-style-type: none;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}
.login-box .info ul.off {
	display: none;
}
.login-box .info li {
	width: 45%;
}
