.techinfo {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.info-shadow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.2;
}
