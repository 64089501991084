.dct-cnt-main-body-row-col {
	padding-top: 5px;
	padding-bottom: 5px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 12px;
	border-bottom: 1px solid #E5E5E5;
}

.dct-cnt-main-body-row-col > div {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}