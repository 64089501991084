.pg-menu {
	float: left;
	width: 300px;
	height: 100%;
	padding-left: 10px;
	transition-property: width;
	transition-duration: 0.5s;
}
.pg-menu.off {
	width: 60px;
	padding: 0;
}
