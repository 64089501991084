.input-base .input {
	display: flex;
	justify-content: space-between;
	background-color: #f3f3f3;
	border: 2px solid #f3f3f3;
	border-radius: 20px;
	padding: 0 10px;
	margin: 4px 0;
}

.input-base .input-ico {
	background-color: #f3f3f3;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 30px;
	width: 15px;
	margin: 0 3px;
	cursor: pointer;
}

/* Input */
.input-base input {
	background-color: #f3f3f3;
	border: none;
	border-radius: 20px;
	padding: 0 3px;
	height: 30px;
}

.input-base.error .input:hover,
.input-base .input:hover,
.input-base .input:focus {
	border: 2px solid #e5e5e5;
}
