.login-box .window {
	width: 500px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}
.login-box .window .btn-login {
	display: flex;
	justify-content: space-around;
	padding-bottom: 20px;
}
.login-box .phone {
	display: block;
	text-align: center;
}
@media screen and (max-width: 600px) {
	.login-box .window {
		min-width: 150px;
		max-width: 85%;
	}
	
}