.tbl-head-com-list {
	position: absolute;
	top: 90%;
	left: 20%;
	width: 600px;
	padding: 30px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	z-index: 1;
}
.tbl-head-com-list ul {
	margin-top: 20px;
	list-style: none;
}
.tbl-head-com-lst-close {
	position: absolute;
	top: 0;
	right: -60px;
	width: 50px;
	height: 50px;
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	cursor: pointer;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
}
