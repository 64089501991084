.login-box .title {
	position: relative;
	height: 80px;
	margin-bottom: 20px;
	border-radius: 20px 20px 0 0;
}
.login-box .title div {
	position: absolute;
	top: 20px;
	left: 20px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
}
.login-box .title p {
	position: absolute;
	top: 23px;
	left: 80px;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
}

@media screen and (max-width: 500px) {
	.login-box .title p {
		font-size: 16px;
		top: 15px
	}

	.login-box .title {
		height: 50px;
	}

	.login-box .title div {
		top: 10px;
		left: 20px;
		width: 30px;
		height: 30px;
	}
}