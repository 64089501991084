.no-pointer {
	pointer-events: none;
}
.skin-progress {
	text-align: center;
}
progress {
	-webkit-appearance: none;
	margin: 4px 0;
}
::-webkit-progress-bar {
	background-color: #f3f3f3;
	height: 30px;
	border-radius: 20px;
	border: 1px solid #f3f3f3;
	overflow: hidden;
  }
::-webkit-progress-value {
	background-color: #5aba0c;
  }
  
::-moz-progress-bar {
	background-color: #5aba0c;
  }