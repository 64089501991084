.crumb {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-color: silver; */
}

.crumb .breadcrumb {
  cursor: pointer;
}

.crumb .breadcrumb + .breadcrumb:before {
  /* padding: 0 0px; */
  content: "/\00a0";
}

.crumb .breadcrumb:last-child {
  color: #5aba0c;
  pointer-events: none;
}

.crumb .breadcrumb:hover {
  text-decoration: underline;
}
