.form-head-ttl-command {
  position: relative;
  display: flex;
  align-items: center;
}
.form-head-ttl-com-name {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}
