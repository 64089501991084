.login-box {
	position: relative;
	width: 100vw;
	height: 100vh;
	/* background-repeat: no-repeat; */
	/* background-size: cover */
	
}
.login-box .phone {
	margin-top: 20px;
}
.login-box .shadow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	/* background-color: #fff; */
	/* opacity: 0.2; */
}
.login-box .container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	flex-flow: column;
	align-items: center;
	width: 100vw;
	height: 100vh;
}
.phone a :active, /* активная/посещенная ссылка */
.phone a :hover,  /* при наведении */
.phone a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.f {
	position: absolute;
    right: 0;
    height: 100vh;
    width: 40vh;
}
.flet {
	position: absolute;
    left: 20px;
    top: 40px;
    width: 31vh;
    height: 10vh;
}

@media screen and (max-width: 500px) {
	.f {
		display: none;
	}
	.flet {
		display: none;
	}
	input {
		font-size: 16px;
	}
	.login-box .shadow {
		opacity: 0.7;
	}
	.skin-login {
		all: unset;
	}
	.phone a :active, /* активная/посещенная ссылка */
	.phone a :hover,  /* при наведении */
	.phone a {
		text-decoration: none;
		color: rgb(0, 0, 0);
	}
}