.ms-ttl-right-page {
	position: relative;
	display: flex;
	align-items: center;
	height: 30px;
}
.ms-ttl-right-page > span {
	display: flex;
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	color: #b5b5b5;
	border: 1px solid #b5b5b5;
}
.ms-ttl-right-page > span.on {
	color: #fff;
	background-color: #5aba0c;
	border: 1px solid #5aba0c;
}
.ms-ttl-right-page-title {
	z-index: 1;
	display: none;
	position: absolute;
	top: 110%;
	left: -70px;
	width: 200px;
	padding-top: 5px;
	padding-left: 30px;
	padding-right: 10px;
	padding-bottom: 5px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	background-color: #fff;
	background-position: 10px 10px;
	background-repeat: no-repeat;
	background-size: 12px;
}
.ms-ttl-right-page:hover .ms-ttl-right-page-title {
	display: block;
}
