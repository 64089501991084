.footer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
	padding-right: 20px;
	padding-left: 10px;
}

.footer a {
	color: black;
	text-decoration: none;
	margin-right: 30px;
}
.footer a:hover {
	text-decoration:underline;
}
.offer {
	background: #F3F3F3;
	border-radius: 20px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 14px;
}
.offer p {
	padding-left: 20px;
}
.docs {
	display: flex;
}