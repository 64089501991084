.tbl-head-command {
	position: relative;
	display: flex;
	align-items: center;
}
.tbl-head-com-name {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
}
