.form-page-num input {
  width: 60px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  color: #ea2828;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  border: 1px solid #5aba0c;
  border-radius: 20px;
}
.form-page-num label {
  margin-left: 5px;
}
