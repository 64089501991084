.reset-box .mess {
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
	list-style-type: none;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}
.reset-box .mess li {
	padding-left: 20px;
	background-position: left center;
	background-size: 12px;
	background-repeat: no-repeat;
}
