.tbl-page-num {

}
.tbl-page-num input {
	width: 60px;
	padding-top: 3px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 3px;
	color: #EA2828;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	border: 1px solid  #5ABA0C;
	border-radius: 20px;
}
.tbl-page-num label {
	margin-left: 5px;
}
