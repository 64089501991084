.login-btn {
	padding-top: 15px;
	text-align: center;
	padding-bottom: 5px;
}
.login-btn button {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	border: 0;
	border-radius: 20px;
	padding-left: 30px;
	padding-right: 30px;
	height: 40px;
}

.window >.login-btn {
	padding-top: 0;
}

@media screen and (max-width: 600px) {
	.login-btn button {
		padding-left: 15px;
		padding-right: 15px;
		height: 30px;
		/* display: none; */
	}
}
