.dct-content {
	position: absolute;
	top: 10px;
	right: 80px;
	padding: 30px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}
.dct-close {
	position: absolute;
	top: 0;
	right: -60px;
	width: 50px;
	height: 50px;
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	cursor: pointer;
}
@media screen and (max-width: 600px) {
	.dct-content {
		position: absolute;
		top: 0;
		right: 0;
		width: calc(100vw - 10px);
		margin: 5px;
		height: calc(100vh - 10px);
		padding: 10px;
	}
	.dct-close {
		top: 5px;
		right: 5px;
		width: 40px;
		height: 40px;
	}
}