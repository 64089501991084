.skin-cmp-notify-note {
	min-width: 200px;
	max-width: 430px;
	min-height: 30px;
	max-height: 200px;
	overflow: hidden;
	padding: 5px 48px 5px 10px;
	z-index: 99;
	font-weight: bold;
	position: relative;
	border-radius: 16px;
}

.skin-cmp-notify-note:not(:last-child) {
	margin-bottom: 8px;
}

.skin-cmp-notify-note.n-info {
	background-color: #2196f3;
}

.skin-cmp-notify-note.n-success {
	background-color: #4caf50;
}

.skin-cmp-notify-note.n-warning {
	background-color: #ff9800;
}

.skin-cmp-notify-note.n-error {
	background-color: #f44336;
}

.skin-cmp-notify-note .closeBtn {
	position: absolute;
	top: 12px;
	right: 12px;
	background: transparent;
	padding: 0;
	border: none;
	cursor: pointer;
}

.skin-cmp-notify-note,
.skin-cmp-notify-note .ico-r {
	color: #fff;
}
.skin-cmp-notify-note .ico-r {
	color: #fff;
	position: absolute;
	top: 3px;
	right: 0px;
	height: 24px;
	width: 24px;
	background-size: 12px;
}