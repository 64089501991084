.tbl-head-com-view {
	color: #EE5130;
	padding-right: 15px;
	background-position: right 9px;
	background-repeat: no-repeat;
	background-size: 8px;
	cursor: pointer;
}
.tbl-head-com-view:hover {
	text-decoration: underline;
}
.tbl-head-com-view.off {
	cursor: default;
	text-decoration: none;
}
