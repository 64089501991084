.links {
    margin-top: 50px;
    width: 520px;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 500px) {
	.links {
		margin-top: 20px;
		width: 320px;
		display: flex;
		justify-content: space-around;
	}
}