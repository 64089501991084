.input-file input[type='file'] {
	display: none;
}

.input-file .file > span {
	padding: 0 3px;
	height: 30px;
	line-height: 30px;
	flex-grow: 1;
	font-size: small;
}

.input-file .file > .name {
	min-width: 80px;
	padding-right: 3px;

	white-space: nowrap; /* Запрещаем перенос строк */
	overflow: hidden; /* Обрезаем все, что не помещается в область */
	text-overflow: ellipsis;
}
.input-file > label {
	display: block;
	padding-left: 12px;
}

.input-file .file {
	display: flex;
	align-items: center;
	height: 34px;
	background-color: #f3f3f3;
	border: 2px solid #f3f3f3;
	border-radius: 20px;
	padding: 0 10px;
	margin: 4px 0;
}

.input-file .file .ctrl .edit,
.input-file .file .ctrl .load,
.input-file .file .ctrl .del {
	border: none;
	margin: 0px;
	color: #fff;
	height: 24px;
	min-width: 24px;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 70%;
	cursor: pointer;
}

.input-file .file .ctrl {
	display: contents;
}
.input-file .file .ctrl .del:hover,
.input-file .file .ctrl .load:hover,
.input-file .file .ctrl label:hover {
	background-color: #f3f3f3;
	border: 2px solid #f3f3f3;
}

.input-file.error .file:hover,
.input-file .file:hover,
.input-file .file:focus {
	border: 2px solid #e5e5e5;
}
