.mess-cnt-act-itm {
	color: #5ABA0C;
	padding-left: 25px;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	background-color: rgba(90, 186, 12, 0.15);
	background-position: 10px center;
	background-repeat: no-repeat;
	background-size: 12px;
	border-color: #fff;
}
.mess-cnt-act-itm:hover {
	background-color: #fff;
	border-color: #5ABA0C;
}
