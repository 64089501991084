.cmp-field {
	display: inline-block;
	padding: 0 5px;
	vertical-align: bottom;
}

.cmp-field:has(img) {
	vertical-align: top;
	display: inline-block;
	padding: 0 5px;
}

::placeholder {
	color: #bfbfbf;
}
