.pict {
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-size: 50px;
	border-radius: 10px;
	justify-self: center;
}
.lnk a {
	text-decoration: none;	
	color: rgb(255, 255, 255);
	font-size: 18px;
	font-weight: 450;
	display:grid;
	text-align: center;
}
.lnk  a:hover {
	text-decoration:underline;
	font-weight: 500;
}

@media screen and (max-width: 500px) {
	.lnk a {
		font-size: 14px;
		color:rgb(0, 0, 0)
	}
	
}