.reset-btn {
	padding-top: 15px;
	text-align: center;
	padding-bottom: 15px;
}
.reset-btn button {
	height: 40px;
	padding-left: 20px;
	padding-right: 20px;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	border: 0;
	border-radius: 20px;
}

.window >.reset-btn {
	padding-top: 0;
}

@media screen and (max-width: 600px) {
	.reset-btn button {
		padding-left: 20px;
		padding-right: 20px;
		height: 30px;
	}
}