.tbl-footer {
	position: absolute;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 50px;
}
