/* Error Styling */
.input-img.error,
.input-area.error .area,
.input-time.error .time,
.input-file.error .file,
.input-base.error .input {
	border: 2px solid #ea2828;
}

.input-img .error-message li,
.input-file .error-message li,
.input-base .error-message li {
	list-style-type: none;
	font-size: 14px;
	color: #ea2828;
}

.input-img.error,
.input-file.error
.input-base.error {
	position: relative;
}

.input-img .error-message,
.input-file .error-message,
.input-base .error-message {
	position: absolute;
	width: max-content;
	z-index: 1;
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
}


