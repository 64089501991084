.form-head-ttl-com-lst-item {
  padding-left: 20px;
  cursor: pointer;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.form-head-ttl-com-lst-item:hover {
  text-decoration: underline;
}
.form-head-ttl-com-lst-item.on {
  color: #5aba0c;
}
.form-head-ttl-com-lst-item + .form-head-ttl-com-lst-item {
  margin-top: 5px;
}
