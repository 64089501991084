.frm-head-com-cnt-item {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.frm-head-com-cnt-item:hover {
	color: #5aba0c;
	text-decoration: underline;
}
.frm-head-com-cnt-itm-tit {
	font-style: normal;
	font-weight: normal;
}
