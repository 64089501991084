.signup-box .window {
	width: 500px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}
.signup-box .window .btn-signup{
	display: flex;
	justify-content: space-around;
}

@media screen and (max-width: 600px) {
	.signup-box .window {
		width: 320px;
		margin-top: 10px;
	}
}