.mn-head {
	display: flex;
	align-items: center;
	height: 30px;
	margin-left: 10px;
	margin-right: 20px;
}
.mn-hd-dlm {
	width: 8px;
	height: 8px;
	margin-right: 10px;
	border-radius: 50%;
	background-color: #D5D5D5;
}
.mn-hd-title {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
}
