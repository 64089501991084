.tbl-main-head-item {

}
.tbl-main-head-item > p {
	padding-left: 10px;
}
.tbl-main-head-itm-filter {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 30px;
	padding-bottom: 5px;
	background-color: #F3F3F3;
	border-radius: 20px;
}
.tbl-main-head-itm-filter.dictionary {
	padding-right: 50px;
}
.tbl-main-head-itm-filter + .tbl-main-head-itm-filter {
	margin-top: 3px;
}
.tbl-main-head-itm-flt-value {
	width: 100%;
}
.tbl-main-head-itm-flt-type {
	position: absolute;
	top: 0;
	right: 10px;
	width: 20px;
	height: 100%;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px;
	border: 0;
}
.tbl-main-head-itm-flt-dict {
	position: absolute;
	top: 0;
	right: 30px;
	width: 20px;
	height: 100%;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px;
	border: 0;
}
.tbl-main-head-itm-filter input {
	width: 100%;
	border: 0;
	background-color: inherit;
}
